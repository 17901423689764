import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthMiddleware from "./utils/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "ladda/dist/ladda-themeless.min.css";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const Login = React.lazy(() => import("./views/Login/Login"));
const ResetPage = React.lazy(() => import("./views/ResetPage/ResetPage"));
const UpdatePassword = React.lazy(() =>
  import("./views/UpdatePassword/UpdatePassword")
);
const Error403 = React.lazy(() => import("./views/HalamanError/Error403"));

class App extends Component {
  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const isAuth = AuthMiddleware.validateToken();
        return isAuth ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );


  render() {
    const { PrivateRoute } = this;
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <ToastContainer />
          <Switch>
            <Route
              exact
              path="/asikk-webadmin/403"
              name="Error 403"
              render={(props) => <Error403 {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/token/:token"
              name="Update Password Page"
              render={(props) => <UpdatePassword {...props} />}
            />
            <Route
              exact
              path="/lupa-kata-sandi"
              name="Reset Password"
              render={(props) => <ResetPage {...props} />}
            />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
