import Axios from "axios";

import headers from "../config/header-config.json";

const { REACT_APP_AS, REACT_APP_AMS } = process.env;
const url = REACT_APP_AS;
const urlams = REACT_APP_AMS;

const get = ({ path, params }) => Axios.get(url + path, { params, headers }),
  getRestrict = ({ path, params }) => {
    const { kodePerusahaan, idUser } =
      JSON.parse(localStorage.getItem("userProfile")) || {};
    return Axios.get(url + path, {
      params: { ...params, kodePerusahaan, idUser },
      headers,
    });
  };
const post = ({ path, data, config = false }) => {
    const { idUser } = JSON.parse(localStorage.getItem("userProfile")) || {};
    return Axios.post(url + path, { ...data, idUser }, {headers}, config);
  },
  postams = ({ path, data, config = false }) => {
    const { idUser } = JSON.parse(localStorage.getItem("userProfile")) || {};
    return Axios.post(urlams + path, { ...data, idUser }, {headers}, config);
  },
  postImage = ({ path, data }) => Axios.post(url + path, data, {headers}),
  put = ({ path, data }) => {
    const { idUser } = JSON.parse(localStorage.getItem("userProfile")) || {};
    return Axios.put(url + path, { ...data, idUser }, {headers});
  },
  remove = ({ path, data = {} }) => {
    const { idUser } = JSON.parse(localStorage.getItem("userProfile")) || {};
    return Axios.delete(url + path + "/" + idUser, {headers}, data);
  },
  removewithoutuid = ({ path, data = {} }) => {
    return Axios.delete(urlams + path ,{headers}, data);
  },
  forgotpass = ({data}) => {
    console.log(data, 'payload')
    // return Axios.post(urlams + path, {data}, {headers});
  };

export { get, post, put, remove, getRestrict, postImage, removewithoutuid, postams, forgotpass};
