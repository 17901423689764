import Axios from "axios";
import SSOConfig from "../config/sso-config.json";
import headers from "../config/header-config.json";
import qs from "querystring";
import JWT from "jsonwebtoken";
import Cookie from "universal-cookie";
import { _getProfile } from "./Profile";
import { SweetToast, ThrowToast } from "./Format";
import moment from "moment";
import { getRestrict, forgotpass } from "../utils/Request";
import axios from "axios";

const cookie = new Cookie();
const { REACT_APP_AMS } = process.env;
const urlams = REACT_APP_AMS;

function _getSessionWithRefreshToken() {
  const _AuthMiddleware = new AuthMiddleware(),
    refresh_token = cookie.get("refresh_token"),
    fetchResource = {
      grant_type: "refresh_token",
      client_id: _AuthMiddleware.client_id,
      client_secret: _AuthMiddleware.client_secret,
      refresh_token,
    };
  return Axios.post(
    _AuthMiddleware.urlKeycloak + "/token",
    qs.stringify(fetchResource)
  ).then((response) => {
    const { access_token, refresh_token } = response.data;
    cookie.set("access_token", access_token, { path: "/" });
    cookie.set("refresh_token", refresh_token, { path: "/" });
  });
}

function _userInfo(access_token) {
  const _AuthMiddleware = new AuthMiddleware();
  return Axios.post(
    _AuthMiddleware.urlKeycloak + "/userinfo",
    {},
    { headers: { Authorization: "Bearer " + access_token } }
  ).then(async (response) => {
    const { email } = response.data;
    let { companyProfile, employeeProfile, userProfile } = await _getProfile(
      email
    );
    companyProfile = JSON.stringify(companyProfile);
    employeeProfile = JSON.stringify(employeeProfile);
    userProfile = JSON.stringify(userProfile);
    return { companyProfile, employeeProfile, userProfile };
  });
}

function AuthMiddleware() {
  this.urlKeycloak = `${SSOConfig["auth-server-url"]}realms/${SSOConfig.realm}/protocol/openid-connect`;
  this.urlAppsmanager = REACT_APP_AMS;
  this.client_secret = SSOConfig.credentials.secret;
  this.client_id = SSOConfig.clientId;
  this.accessTokenValid = false;
  this.refreshTokenValid = false;
}

AuthMiddleware.prototype.forgotPassword = function (data, link) {
  const emaiuser = data.email;
  const headers = {
    'X-Api-Key': `NhWs64XVVHk1He9qbj1c04IKWO6f2O8b`,
    'Content-Type': 'application/json',
  };
  const requestBody = {
    'email': data.email,
  };

  const resourceFetch = {
    method: 'post', // Ubah method sesuai kebutuhan (post, get, dll)
    url: `${urlams}/password/create`, // Sesuaikan dengan endpoint yang benar
    data: requestBody,
    headers: headers,
  };

  return axios(resourceFetch)
    .then(() => {
      SweetToast(
        `Email untuk reset password berhasil dikirim ke ${emaiuser}.`,
        'success'
      );
      return link('/login');
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return SweetToast(
          `Email ${emaiuser} tidak ditemukan dalam database.`,
          'error'
        );
      } else {
        console.error('Error:', err);
        // Handle other errors here
      }
    });
};

AuthMiddleware.prototype.resetPassword = function (data, link) {
  const headers = {
    'X-Api-Key': `NhWs64XVVHk1He9qbj1c04IKWO6f2O8b`,
    'Content-Type': 'application/json',
  };
  const { newPassword, confirmPassword, token } = data;

  return Axios.put(this.urlAppsmanager + "/password/reset/" + token, {
    newPassword,
    confirmPassword,
  }, {
    headers: { ...headers },
  })
    .then(() => {
      SweetToast(
        `Password berhasil diperbarui, coba login dengan kata sandi yang
        baru.`,
        "success"
      );
      return link("/login");
    })
    .catch((err) => {
      if (err.response.status === 404)
        return SweetToast(
          "Password gagal diperbarui, hubungi administrator.",
          "error"
        );
    });
};

AuthMiddleware.prototype.actionLogin = async function (data) {
  this.data = data;
  const fetchResource = {
    grant_type: "password",
    client_id: this.client_id,
    client_secret: this.client_secret,
    ...this.data,
  };
  return Axios.post(this.urlKeycloak + "/token", qs.stringify(fetchResource))
    .then(async (response) => {
      const { access_token, refresh_token } = response.data,
        { companyProfile, employeeProfile, userProfile } = await _userInfo(
          access_token
        ),
        { name } = JWT.decode(access_token);
      const { jenisUser, flagBlokir, email, idUser } =
        JSON.parse(userProfile) || {};

      /* Validasi Jika Bukan Admin */
      if (jenisUser === "1") {
        SweetToast(
          `Maaf, User ${email} tidak diizinkan untuk mengakses aplikasi.`,
          "warning"
        );
        return false;
      }

      /* Validasi Jika Akun Dalam Status Blokir */
      if (flagBlokir === "1") {
        SweetToast(`Maaf, User ${email} dalam status diblokir.`, "error");
        return false;
      }

      /* Cookie Set Token */
      cookie.set("access_token", access_token, { path: "/" });
      cookie.set("refresh_token", refresh_token, { path: "/" });

      /* LocalStorage Set Data User */
      localStorage.setItem("userProfile", userProfile);
      localStorage.setItem("employeeProfile", employeeProfile);
      localStorage.setItem("companyProfile", companyProfile);

      SweetToast(`Authentikasi Sukses, Selamat Datang ${name}`, "success");

      const resourceFetch = {
        url: this.urlAppsmanager + "/user/update/" + idUser,
        data: {
          lastLoginWeb: moment().format(),
          nikRekam: email
        },
      };

      Axios.put(resourceFetch.url, resourceFetch.data, { headers })
        .then((response) => {
          // console.log("Success!", response);
        })
        .catch((error) => {
          // console.log("There was an error!", error);
        });
      return true;
    })
    .catch(() => {
      SweetToast("Username / Password yang dimasukan masukan salah.", "error");
      return false;
    });
};

AuthMiddleware.prototype.actionLoginCompany = async function (kodekantor) {
  const data = {
    "kodePerusahaan": kodekantor
  }

  axios.post(`${REACT_APP_AMS}/user/login/login-as-company`, data, { headers }).then(async (res) => {
    /* Cookie Set Token */
    const { access_token, refresh_token } = res.data.item;
    cookie.set("access_token", access_token, { path: "/" });
    cookie.set("refresh_token", refresh_token, { path: "/" });
    const { name, email } = JWT.decode(access_token);
    // this._getProfile(email);

    // const {access_token, refresh_token} = res.data.item,

    const { companyProfile, employeeProfile, userProfile } = await _getProfile(
      email
    );
    //   {name} = JWT.decode(access_token);
    // const {jenisUser, flagBlokir, email} =
    // JSON.parse(userProfile) || {};


    /* Validasi Jika Bukan Admin */
    // if (jenisUser !== "0" && jenisUser !== "2") {
    //   ThrowToast(
    //     `Maaf, User ${email} tidak diizinkan untuk mengakses aplikasi.`,
    //     "warn"
    //   );
    //   return false;
    // }

    // /* Validasi Jika Akun Dalam Status Blokir */
    // if (flagBlokir === "1") {
    //   ThrowToast(`Maaf, User ${email} dalam status diblokir.`, "error");
    //   return false;
    // }


    /* LocalStorage Set Data User */
    localStorage.setItem("userProfile", JSON.stringify(userProfile));
    localStorage.setItem("employeeProfile", JSON.stringify(employeeProfile));
    localStorage.setItem("companyProfile", JSON.stringify(companyProfile));

    SweetToast(`Authentikasi Sukses, Selamat Datang ${email}`, "success");
    return true;
  })
    .catch(() => {
      SweetToast("Username / Password yang dimasukan masukan salah.", "error");
      return false;
    });

};

AuthMiddleware.prototype.actionLogout = function () {
  const refresh_token = cookie.get("refresh_token"),
    fetchResource = {
      client_id: this.client_id,
      client_secret: this.client_secret,
      refresh_token,
    };
  return Axios.post(this.urlKeycloak + "/logout", qs.stringify(fetchResource))
    .then(() => {
      cookie.remove("access_token");
      cookie.remove("refresh_token");
      localStorage.clear();
    })
    .catch(() => {
      cookie.remove("access_token");
      cookie.remove("refresh_token");
      localStorage.clear()
    }
    );
};

AuthMiddleware.prototype.validateToken = function () {
  const { access_token, refresh_token } = cookie.getAll(),
    decodeAccessToken = JWT.decode(access_token) || { exp: 0 },
    decodeRefreshToken = JWT.decode(refresh_token) || { exp: 0 },
    currentTime = Date.now().valueOf() / 1000;
  this.accessTokenValid = currentTime < decodeAccessToken.exp;
  this.refreshTokenValid = currentTime < decodeRefreshToken.exp;
  if (!this.accessTokenValid && this.refreshTokenValid) {
    _getSessionWithRefreshToken();
    return true;
  } else if (!this.accessTokenValid && !this.refreshTokenValid) {
    return false;
  }
  return true;
};

AuthMiddleware.prototype.isExpiredSession = function () {
  return this.accessTokenValid && this.refreshTokenValid;
};

AuthMiddleware.prototype.userInfo = function (access_token) {
  return _userInfo(access_token);
};
export default new AuthMiddleware();
