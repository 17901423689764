import React from "react";
import momentTz from "moment-timezone";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import {Button, Col, Row} from "reactstrap";
import {Bar} from "react-chartjs-2";
const location = "Asia/Jakarta";

export const formatDate = (waktu) =>
  waktu ? momentTz.tz(waktu, location).format("DD/MM/YYYY H:mm:ss") : "-";

export const ThrowToast = (message, action) => {
  const title =
    action === "success"
      ? "BERHASIL!"
      : action === "error"
      ? "KESALAHAN!"
      : "PERINGATAN!";
  return toast[action](
    <div>
      <h5 style={{ paddingLeft: 23, paddingTop: 10 }}>{title}</h5>
      <ul>
        <li>{message}</li>
      </ul>
    </div>
  );
};

export const SweetToast = (message, action) => {

  const title =
    action === "success"
      ? "BERHASIL!"
      : action === "error"
      ? "KESALAHAN!"
      : "PERINGATAN!";

  const Toast = SweetAlert.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', SweetAlert.stopTimer)
      toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
    }
  })

  Toast.fire({
    icon: action,
    title: title,
    text: message
  })
}

export const IfEditNotHavingData = (props, module) => {
  return (
    !props.location.data &&
    props.values.action !== "tambah" &&
    props.history.push("/" + module)
  );
};

export const disableRole = (position) =>{
  const roleArr = JSON.parse(localStorage.getItem("roleArr"));
  return roleArr.includes(position);
}

export function seo(data = {}) {
  data.title = data.title || 'Default title';
  data.metaDescription = data.metaDescription || 'Default description';

  document.title = data.title;
  document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
}

export const StatusFormat = (message, value) => {
  switch (value) {
    case 'success':
      return (
        <div>
          <h5>
            <Button
              outline
              style={{
                padding: "8px",
                fontSize: "12px",
                pointerEvents: "none",
                backgroundColor: "#b0f7da",
                color: "#178558"
              }}
              color={"success"}
            >
              {message}
            </Button>
          </h5>
        </div>
      );
    case 'warning':
      return (
        <div>
          <h5>
            <Button
              outline
              style={{
                padding: "8px",
                fontSize: "12px",
                pointerEvents: "none",
                backgroundColor: "#ffedcc",
                color: "#d97f09"

              }}
              color={"warning"}
            >
              {message}
            </Button>
          </h5>
        </div>
      );
    case 'danger':
      return (
        <div>
          <h5>
            <Button
              outline
              style={{
                padding: "8px",
                fontSize: "12px",
                pointerEvents: "none",
                backgroundColor: "#FF9393",
                color: "#A60000"
              }}
              color={"danger"}
            >
              {message}
            </Button>
          </h5>
        </div>
      );
    default:
      return null;
  }

};
