import Axios from "axios";
import headers from "../config/header-config.json";

const {REACT_APP_AMS, REACT_APP_AS, REACT_APP_API_KEY} = process.env;

const _getRole = async (idUser) => {
  // const { idUser } =
  // JSON.parse(localStorage.getItem("userProfile")) || {};

  let params = `/user-detail/adv-list-dx?filter=[["idUser","contains","${idUser}"]]`;

  // fetch(encodeURI(REACT_APP_AMS) + params, {
  //   method: 'GET',
  //   headers
  // })
  fetch(encodeURI(REACT_APP_AMS) + params, {
    method: "GET",
    headers
  })
    // .then(handleErrors)
    .then(response => response.json())
    .then((data) => {
      let roleArr = [];

      data.items.forEach((el) => {

        if (!roleArr.includes(el['td_role']['namaRole']))
          roleArr.push(el['td_role']['namaRole'])
      });
      localStorage.setItem('roleArr', JSON.stringify(roleArr));
      return roleArr
    })
    .catch(err => {
      console.log('some error', err);
      // callback(null);
    });
};

const _getMenu = async (idUser) => {
  return await Axios.get(REACT_APP_AMS + "/user-detail/menu/" + idUser, {headers}).then(
    ({data}) => {
      const listMenu = data.items;
      const listMenuTree = data.itemsFlat;
      // EnvActions.setMenu(listMenu);
      // EnvActions.setMenuTree(listMenuTree);
      return listMenu
        .reduce((arr, {name, url, noUrut, icon, children, role}, idx) => {
          arr[idx] = {
            name,
            role,
            url,
            sort: noUrut,
            icon,
            children,
          };
          return arr;
        }, [])
        .sort((a, b) => a.sort - b.sort);
    }
  );
};

const _getProfile = (email) => {
  const params = {email};
  return Axios.get(REACT_APP_AMS + "/user/user-info-by-email", {
    params, headers
  }).then(async ({data}) => {
    const {kodePerusahaan} = await data.item,
      companyProfile = await Axios.get(
        `${REACT_APP_AS}/perusahaan/kode/${kodePerusahaan}`, {headers}
      ),
      employeeProfile = await Axios.get(
        REACT_APP_AS + "/employee/email/" + email, {headers}
      );
    return {
      companyProfile: companyProfile.data.item,
      employeeProfile: employeeProfile.data.item,
      userProfile: data.item,
    };
  });
};

const _getPerusahaan = (email) => {
  const params = {email};
  return Axios.get(REACT_APP_AMS + "/user/user-info-by-email", {
    params, headers
  }).then(async ({data}) => {
    const {kodePerusahaan} = await data.item,
      companyProfile = await Axios.get(
        `${REACT_APP_AS}/perusahaan/kode/${kodePerusahaan}`, {headers}
      ),
      employeeProfile = await Axios.get(
        REACT_APP_AS + "/employee/email/" + email, {headers}
      );
    return {
      companyProfile: companyProfile.data.item,
      employeeProfile: employeeProfile.data.item,
      userProfile: data.item,
    };
  });
};

export {_getProfile, _getMenu, _getPerusahaan, _getRole};
